import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Layout } from "../components/Layout"
import { Hero } from "../components/Hero"

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero />
      <section>
        <div className='container my-24 mx-auto px-6'>
          <h3 className='mb-8 text-center text-3xl font-bold'>
            Seite nicht gefunden
          </h3>
          <p className='text-center'>
            Die von Ihnen aufgerufene Seite konnte leider nicht gefunden werden.
          </p>
        </div>
        <div className='flex w-full'>
          <StaticImage
            class='mx-auto mb-6 w-96'
            src='../images/404.png'
            alt='404'
          />
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => {
  return <title>Zemann-Zipser: Nicht gefunden</title>
}

export default NotFoundPage
